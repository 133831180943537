<template>
	<div class="full-height">

		<form name="form_chk" method="get">
			<input type="hidden" name="m" value="checkplusService">     <!-- 필수 데이타로, 누락하시면 안됩니다. -->
			<input type="hidden" name="EncodeData" value="">  <!-- 위에서 업체정보를 암호화 한 데이타입니다. -->
			<input type ="hidden" name="recvMethodType" value="get">
			<input type ="hidden" name="redirectUrl" value="">
			<!--
			<a href="javascript:fnPopup();"> CheckPlus 안심본인인증 Click</a>
			-->
		</form>

		<Popup_confirm
			v-if="mode == 'success'"
			:not_cancel="true"
			@click="$emit('click')"
		>
			<template v-slot:title>본인 인증 완료</template>
			<template
				v-slot:sub-txt
			>
				본인 인증이 완료되었습니다.
			</template>
		</Popup_confirm>

		<Popup_confirm
			v-else-if="mode == 'fail'"
			@click="$emit('cancel')"
			:not_cancel="true"
		>
			<template v-slot:title>본인 인증 처리 실패</template>
			<template
				v-slot:sub-txt
			>
				본인 인증 등록 처리가 실패하였습니다. 본인 인증을 다시 한번 시도해주세요.
			</template>
		</Popup_confirm>
	</div>
</template>
<script>

import Popup_confirm from "@/view/Layout/PopupConfirm";
export default {
	props: ['user']
	, components: {Popup_confirm}
	, name: 'nice2'
	, data: function(){
		return {
			program: {
				name: this.$language.common.mypage
				, not_footer: true
				, type: 'mypage'
				, title: this.$language.common.mypage
				, from: 'main'
			}
			, mode: 'confirm'
			, disabled: false
			, item: {}
			, nice_url: 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'
		}
	}
	, methods: {
		setMode: function(mode){
			this.mode = mode
		}
		, niceResult: function(call){
			this.fnPopup(call.items.enc_data)
		}
		, fnPopup: function() {
			// console.log('fnPopup', this.item.crypto_data)
			// window.name ="Parent_window";
			// window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
			document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
			document.form_chk.EncodeData.value = this.item.crypto_data
			document.form_chk.submit();

		}
		, messageResult: function(e){
			const call = e.data
			console.log('messageResult', call)
			if(call.success){
				this.$set(this.item, 'payload', call.payload)
				this.$set(this.item, 'certificationInformation', call.payload)
				this.$set(this.item, 'mobilPhoneNumber', call.phone_no)
				this.disabled = true
				this.postConfirm()
			}else{
				this.disabled = false
				this.$emit('alertMsg', 'error', call.resp_message)
			}
		}
		, onRequest: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_personal_confirm_request
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if(result.success){
					this.item = result.data
					this.mode = 'personal'
					this.fnPopup()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postConfirm: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_personal_confirm_request
					, data: {
						member_number: this.user.member_number
						, self_certfc_request_number: this.item.self_certfc_request_number
						, self_certfc_info: this.item.payload
					}
					, type: true
				})
				if(result.success){
					this.item = result.data
					this.mode = 'success'
					this.$bus.$emit('updateUser', { key: 'mphone_self_certfc_fg', value: 'Y'})
				}else{
					this.mode = 'fail'
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.onRequest()
		window.removeEventListener('message', this.messageResult)
		window.addEventListener('message', this.messageResult);
	}
}
</script>